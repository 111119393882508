/* XS */
@media (max-width: 767px) {
  
}

/* XS, SM */
@media (max-width: 991px) {
    .authContainer{
        .authCard{
            width: 90%;
            img {
                height: 6em;
            }
            .formContainer{
                form {
                    padding: 0 5%;
                }
            }
        }
    }
}

/* SM */
@media (min-width: 768px) and (max-width: 991px) {
  
}

/* MD */
@media (min-width: 992px) and (max-width: 1199px) {
  
}

/* MD, LG */
@media (min-width: 992px) {
  
}

/* LG */
@media (min-width: 1200px) {
  
}