@import './material';

/* You can add global styles to this file, and also import other style files */
// @import './reseter';
@import './mixins';
@import './variables';
@import 'frow/frow';
// @import "tailwindcss";


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

mat-tool-bar.mat-tool-bar{
    margin: 0;
}
a {
    color: whitesmoke;
    &:visited{
        color: gray;
    }
    &.dark{
        color: gray;
        &:visited{
            color: lightgray;
        }
    }
}
/* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
mat-card.mat-mdc-card{
    // background: rgba(#34434f, 0.3);
    &.formCard, &.infoCard, &.tableCard, &.chartCard{
        /* TODO(mdc-migration): The following rule targets internal classes of card that may no longer apply for the MDC version. */
        mat-card-title{
            &:not(.noMargin){
                margin-top: 10px !important;
            }
        }
    }
}
/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.tableContainer, .mat-table, .mat-paginator, .mat-button-toggle{
    // background: transparent !important;
}

.col-xs-offset-right-6{
    margin-right: 50%;
}

mat-sidenav.mat-drawer {
    --mat-sidenav-container-divider-color: grey;
}
mat-nav-list{
    a[mat-list-item].menuPadding{
        /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
        .mat-list-item-content{
            padding: 0 20px 0 33px;
        }
    }
}
formly-form{
    *[disabled=disabled] {
        opacity: 1 !important;
        color: black !important;
        * {
            opacity: 1 !important;
            color: black !important;
        }
    }
    *[disabled=disabled] {
        opacity: 1 !important;
        color: black !important;
        * {
            opacity: 1 !important;
            color: black !important;
        }
    }
    *.mat-disabled{
        opacity: 1 !important;
        color: black !important;
        * {
            opacity: 1 !important;
            color: black !important;
        }
    }
    mat-form-field.mat-form-field-type-mat-select{
        transform: translateY(-1px);
    }
}
mat-form-field.mat-mdc-form-field {
    --mat-form-field-container-text-line-height: 1.5;
    --mdc-filled-text-field-container-color: transparent;
    &:hover {
        .mat-mdc-form-field-focus-overlay {
            opacity: 0.04;
        }
    }
    &.mat-focused {
        .mat-mdc-text-field-wrapper {
            .mat-mdc-form-field-focus-overlay {
                opacity: 0.08;
            }
        }
    }
}
mat-card.mat-mdc-card {
    --mdc-outlined-card-outline-width: 0;
    mat-card-title.mat-mdc-card-title {
        padding-left: 24px;
    }
}
mat-nav-list.mat-mdc-nav-list {
    a.mat-mdc-list-item {
        --mdc-list-list-item-one-line-container-height: 48px;
    }
}
mat-button-toggle-group{
    line-height: 36px;
}

.bold {
    mat-form-field{
        // font-weight: 900;
    }
}

.authContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    .authCard{
        background-color: white;
        border-radius: 3%;
        width: 500px;
        padding: 5em 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        img {
            height: 7.5em;
            margin-bottom: 1em;
        }
        .formContainer{
            form {
                padding: 0 10%;
                display: flex;
                flex-direction: column;
                align-items: center;
                ngx-recaptcha2 > div{
                    transform: scale(0.7);
                }
            }
        }
    }
}

.filters-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    mat-form-field {
        margin-right: 8px;
    }
    app-export-button {
        padding-bottom: 27px;
    }
}

.table-footer-total {
    display: flex;
    padding-left: 30%;
    .table-footer-total-wrapper {
        display: flex;
        align-items: center;
        .footer-total-label {
            margin-right: 8px;
        }
        .footer-total-value {
            border-radius: 15px;
            background-color: aliceblue;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            padding: 0 16px;
        }
    }
}

@import './styles-responsive';